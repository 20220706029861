import { styled, TextField, Box, keyframes, IconButton } from '@mui/material';
import Link from 'next/link';
import { borderTop, bottom, flexWrap, position, textAlign } from 'styled-system';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const SoldContainer = styled(Box)(({ theme,background,color }) => ({
  backgroundColor: theme.palette.background.paper,
  color:color,
  zIndex: theme.zIndex.drawer,
  width: '100%',
  [theme.breakpoints.down(750)]: {
    position: 'fixed',
    left: 0,
    bottom: 0,
  },
}));

export const StyledBox = styled(Box)(({ theme,background,color }) => ({
  boxsizing: 'border-box',
  position:'relative',
  height: theme.spacing(2),
  padding:theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap:theme.spacing(2),
  animation: `${fadeIn} 0.75s`,
  flexDirection: 'row',
  background:background || 'linear-gradient(90deg, rgba(52,50,74,1) 0%, rgba(255,110,114,1) 53%, rgba(255,110,114,1) 100%)',
  fontFamily:'Raleway',
  borderBottom: `1px solid ${color}`,
  [theme.breakpoints.down(750)]: {
    height: 'auto',
    flexDirection: 'column',
    textAlign:'center',
    flexWrap:'wrap',
    borderBottom: 'none',
    borderTop: `1px solid ${color}`,
  },
}));

export const StyledBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap:theme.spacing(2),
  fontFamily: "arial, sans-serif",
  position:'relative',
  [theme.breakpoints.down(750)]: {
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap:theme.spacing(1),
  },
}));


export const StyledCode = styled('b')(({ theme }) => ({
  fontFamily: "arial, sans-serif",
  leadingTrim: 'both',
  textEdge: 'cap'
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  display: 'none',
}));

export const StyledRelative = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

export const CopyButton = styled(IconButton)(({ theme }) => ({
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down(750)]: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  }
}));

export const ContentAside = styled(Box)(({ theme }) => ({
  width: '45px',
  height: '45px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position:'relative',
}));

export const BtnUpgrade = styled(Link)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  textDecoration:'none',
  backgroundColor: "white",
  top: theme.spacing(1),
  right: theme.spacing(5),
  zIndex: theme.zIndex.drawer,
  fontFamily:'Raleway',
  fontWeight:'bold',
  color:'black'
}));

export const ContainerFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  gap:theme.spacing(2),
}));
 
 