"use client";
import React, { useEffect, useState } from 'react';
import {
    StyledBox,
    StyledTextField,
    CloseButton,
    StyledBoxContainer,
    ContentAside,
    CopyButton,
    BtnUpgrade,
    SoldContainer,
    ContainerFlex,
    StyledCode
} from './sold.style';
import NextImage from 'common/components/NextImage';
import { PrismicRichText } from 'common/components/PrismicRichText/PrismicRichText';
import { Icon } from 'react-icons-kit';
import {ic_content_copy_twotone} from 'react-icons-kit/md/ic_content_copy_twotone'
import {ic_done_outline} from 'react-icons-kit/md/ic_done_outline'
import { Box } from '@mui/material';
import { Mixpanel } from 'utils/mixpanel';
 

const Sold = ({ promotion }) => {

    useEffect(() => {
        Mixpanel.track('Sold Clicked', {});
      }, []);


    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(promotion?.code_sold);
        setIsCopied(true)
    };

    if(promotion === undefined || promotion === null  || !promotion?.available){
        return null;
    }

  return (
    <SoldContainer background={promotion.background} color={promotion.text_color}>
                <StyledTextField value={promotion?.code_sold ||[]} />

    <StyledBox >
        <Box/>
            <Box>

                <StyledBoxContainer>
                    <ContainerFlex>
                        {
                            promotion?.icon.length > 0 ? 
                            <ContentAside>
                            <NextImage field={promotion?.icon ||[]} fill={true}  /> 
                            </ContentAside> :null
                        }
                       
                        <PrismicRichText field={promotion?.description || []} />
                    </ContainerFlex>

                    {promotion?.code_sold ? (
                        <ContainerFlex>
                            <StyledCode>{promotion?.code_sold}</StyledCode>
                            <CopyButton onClick={handleCopy} size="small">
                                <Icon icon={isCopied ? ic_done_outline: ic_content_copy_twotone}  size={18} style={{color:promotion.text_color}} />
                            </CopyButton>
                        </ContainerFlex>
                    ) : null}
            
                </StyledBoxContainer>
            </Box>

            <ContainerFlex>
                <BtnUpgrade href={promotion.url}>
                    {promotion.cta_redirect}
                </BtnUpgrade>
            </ContainerFlex>

      </StyledBox>

    </SoldContainer>

  );
}

export default Sold;